import {
  Button,
  Popover,
  DialogTitle,
  DialogContent,
  Typography,
  Stack,
  SectionField,
  DisplayField,
} from "@yunex/yds-react";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from "react";
import useWindowSize from "../../../customHooks/windowSize";
import GeoDataContext from "../../../store/geo-data-context";
import HeaderIcon from "./HeaderIcon/headerIcon";
import styles from "./popup.module.scss";

export default function Popup() {
  const size = useWindowSize();
  const context = useContext(GeoDataContext);
  const anchorRef = useRef(null);
  const linkGoogleMaps = `http://maps.google.com/maps?q=&layer=c&cbll=${context.selectedVri.value.properties.Latitude},${context.selectedVri.value.properties.Longitude}&cbp=`;
  const dataDogLink = `https://app.datadoghq.com/dashboard/sxd-tnh-cxm/detail-overzicht-voor-enkele-vri?tpl_var_VRI=${context.selectedVri.value.properties.UdapName}&live=true`;

  let displayWidth;
  if (context.popup.dialogOpen.value === true && size.width < 850) {
    displayWidth = "Calc(100vw - 1rem)";
  } else if (context.popup.dialogOpen.value === true && size.width < 850) {
    displayWidth = "30vw";
  } else if (context.popup.dialogOpen.value === true && size.width >= 850) {
    displayWidth = "30vw";
  } else {
    displayWidth = "30px";
  }

  useEffect(() => {
    return () => {
      context.focusedItem.set(null);
      context.selectedVri.set(null);
      context.popup.dialogOpen.set(!context.popup.dialogOpen.value);
    };
  }, []);

  const handleToggle = () => {
    context.deselect.set(!context.deselect.value);
    context.focusedItem.set(null);
    context.popup.dialogOpen.set(!context.popup.dialogOpen.value);
  };

  return (
    <div>
      {context.selectedVri.value && (
        <Popover
          anchorEl={anchorRef.current}
          className={styles.popover}
          style={{
            left: size.width > 650 ? "350px" : "100vw",
            width: displayWidth,
            minWidth: context.popup.dialogOpen.value === true && `180px`,
            maxWidth: size.width > 650 ? "350px" : "100vw",
          }}
          arrow={false}
          open={context.popup.dialogOpen.value}
          sx={{}}
        >
          <DialogTitle
            onClose={handleToggle}
            padding={"1rem 1rem 0 1rem !important"}
            icon={
              <HeaderIcon
                status={context.selectedVri.value.properties.Status}
              />
            }
            style={{
              backgroundColor: "transparent",
            }}
            sx={{
              " > p": {
                padding: "0 !important",
              },
            }}
          >
            <span className={styles.title}>
              {context.selectedVri.value.properties.Name}
            </span>
          </DialogTitle>
          <DialogContent className={styles.dialog_content__padding}>
            <Typography
              variant="subtitle2"
              component="h4"
              gutterBottom
              className={styles.dialog_subtitle}
            >
              {context.selectedVri.value.properties.DescriptiveName}
            </Typography>
            <Stack gap={1} direction="row" flexWrap="wrap">
              <Button onClick={() => window.open(dataDogLink, "_blank")}>
                Datadog dashboard
              </Button>
              <Button
                color="tertiary"
                onClick={() => window.open(linkGoogleMaps, "_blank")}
              >
                Street view
              </Button>
              {/* <Button aria-label='tertiary-button' color='tertiary' icon>
                      <More16 />
                    </Button> */}
            </Stack>
            <div className={styles.divider} />
            <SectionField insets value="Device information">
              <DisplayField
                insets
                label="Name"
                value={context.selectedVri.value.properties.Name}
                className={styles.display_field__border}
              />
              <DisplayField
                insets
                label="Udap name"
                value={context.selectedVri.value.properties.UdapName}
                className={styles.display_field__border}
              />
              <DisplayField
                insets
                label="Road authority"
                value={context.selectedVri.value.properties.Owner}
                className={styles.display_field__border}
              />
              <DisplayField
                insets
                label="TLC supplier"
                value={context.selectedVri.value.properties.TLC_Vendor}
                className={styles.display_field__border}
              />
              <DisplayField
                insets
                label="OVPrio"
                value={context.selectedVri.value.properties.OvPrio}
                className={styles.display_field__border}
              />
              <DisplayField
                insets
                label="VrachtPrio"
                value={context.selectedVri.value.properties.VrachtPrio}
                className={styles.display_field__border}
              />
              <DisplayField
                insets
                label="Profile"
                value={context.selectedVri.value.properties.Profile}
                className={styles.display_field__border}
              />
              <DisplayField
                insets
                label="Location"
                value={
                  context.selectedVri.value.properties.Latitude +
                  ", " +
                  context.selectedVri.value.properties.Longitude
                }
                className={styles.display_field__border}
              />
            </SectionField>

            {/* <SectionField insets value="Intersection configuration">
              <DisplayField
                insets
                label="Version ID"
                value="5"
                className={styles.display_field__border}
              />
              <DisplayField
                insets
                label="Start date"
                value="21 September 2023"
                className={styles.display_field__border}
              />
            </SectionField> */}
          </DialogContent>
        </Popover>
      )}
    </div>
  );
}
