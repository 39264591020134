import { useEffect, useState } from "react";
import MapBoxGLLayer from "./MapBoxLayer/mapBoxLayer";
import { TileLayer } from "react-leaflet";

export default function TileLayers({ center, zoom }) {
  const [isValidStyle, setIsValidStyle] = useState(false);
  const MAPBOX_ACCESS_TOKEN =
    "sk.eyJ1IjoibWF0dGhpanN2YW5laWprIiwiYSI6ImNsa2pnNWR2eDA5djUzZW42ejVnb3VrdngifQ.-glILxJYDhIabnih8yGcHg";

  useEffect(() => {
    const checkStyleValidity = async () => {
      try {
        const response = await fetch(
          "https://tileserver.centrals.eu.dev.yunextraffic.cloud/styles/basic-preview/style.json"
        );
        console.log(response.status === 200 ? "yunex map" : "default map");
        setIsValidStyle(response.status === 200);
      } catch (error) {
        console.log("default map");
        console.log("Error fetching style, setting default mapLayer: ", error);
        setIsValidStyle(false);
      }
    };
    checkStyleValidity();
  }, []);

  return isValidStyle ? (
    <MapBoxGLLayer
      accessToken={MAPBOX_ACCESS_TOKEN}
      style="https://tileserver.centrals.eu.dev.yunextraffic.cloud/styles/basic-preview/style.json"
      // style="https://api.maptiler.com/maps/abaa2278-581e-438e-8dd0-061478875bf2/style.json?key=GqEm9mDgtRIQ03vhZ0ra"
      attribution="Yunex Traffic 2023"
      zoom={zoom}
      center={center}
    />
  ) : (
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
  );
}
