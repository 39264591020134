import { useState, useEffect } from "react";
import GeoDataContext from "./geo-data-context";
import axios from "axios";

const GeoDataProvider = (props) => {
  const [selectedVri, setSelectedVri] = useState(null);
  const [mapCenter, setMapCenter] = useState([52.04627, 4.51326]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mapZoom, setMapZoom] = useState(8);
  const [geoJSON, setGeoJSON] = useState(null);
  const [focusedItem, setFocusedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [showOk, setShowOk] = useState(true);
  const [showError, setShowError] = useState(true);
  const [showOffline, setShowOffline] = useState(true);
  const [deselect, setDeselect] = useState(false);
  const [showVracht, setShowVracht] = useState(true);
  const [showOv, setShowOv] = useState(true);
  const [decodedCoordinates, setDecodedCoordinates] = useState([]);
  const [clearRoute, setClearRoute] = useState(false);
  const [pins, setPins] = useState([]);

  const addPin = (newPin) => {
    setPins((prevPins) => [...prevPins, newPin]);
  };

  const clearPins = () => {
    setPins([]);
  };

  const deletePin = (id) => {
    setPins((prevPins) => prevPins.filter((pin) => pin.id !== id));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        axios.get("/api").then(
          (response) => {
            setGeoJSON(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    fetchData();
  }, []);

  const geoDataContext = {
    geoData: geoJSON,
    pins: { value: pins, add: addPin, clear: clearPins, delete: deletePin },
    decodedCoordinates: {
      value: decodedCoordinates,
      set: setDecodedCoordinates,
    },
    selectedVri: { value: selectedVri, set: setSelectedVri },
    selectedIndex: { value: selectedIndex, set: setSelectedIndex },
    focusedItem: { value: focusedItem, set: setFocusedItem },
    showOk: { value: showOk, set: setShowOk },
    showError: { value: showError, set: setShowError },
    showOffline: { value: showOffline, set: setShowOffline },
    showVracht: { value: showVracht, set: setShowVracht },
    showOv: { value: showOv, set: setShowOv },
    map: {
      center: { value: mapCenter, set: setMapCenter },
      zoom: { value: mapZoom, set: setMapZoom },
      clearRoute: { value: clearRoute, set: setClearRoute },
    },
    popup: {
      dialogOpen: { value: open, set: setOpen },
    },
    deselect: {
      value: deselect,
      set: setDeselect,
    },
  };

  return (
    <GeoDataContext.Provider value={geoDataContext}>
      {props.children}
    </GeoDataContext.Provider>
  );
};

export default GeoDataProvider;
