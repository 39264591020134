import React from 'react'
import PropTypes from 'prop-types'

export function Moon24({ theme }) {
    Moon24.propTypes = {
        theme: PropTypes.node.isRequired,
    }

    let fillcolor
    if (theme === 'dark') {
        fillcolor = 'black'
    } else {
        fillcolor = 'transparent'
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
                d="M 9.828125 0.23242188 A 12.048852 12 0 0 0 0 12 A 12.048852 12 0 0 0 12.048828 24 A 12.048852 12 0 0 0 24.097656 12 A 12.048852 12 0 0 0 24.021484 10.673828 A 9 9 0 0 1 18 13 A 9 9 0 0 1 9 4 A 9 9 0 0 1 9.828125 0.23242188 z "
                fill={fillcolor}
            />
        </svg>
    )
}
