import { jwtDecode } from "jwt-decode";

export default function useIsLoggedIn() {
  const token = localStorage.getItem("token");
  if (!token) return false;

  const tokenData = jwtDecode(token);
  if (Date.now() > tokenData.exp * 1000) {
    localStorage.removeItem("token");
    return false;
  }
  return true;
}
