import { useContext, useState } from "react";
import GeoDataContext from "../../../store/geo-data-context";
import { TextFormInput, Button, CardContent, Card } from "@yunex/yds-react";
import { Close16 } from "@yunex/yds-icons";
import polyline from "polyline-encoded";
import styles from "./stringDecoder.module.scss";

export default function StringDecoder() {
  const context = useContext(GeoDataContext);
  const [isError, setIsError] = useState(false);
  const [encodedString, setEncodedString] = useState("");

  const isValidCoordinates = (lat, lon) => {
    const latPattern = /^-?([1-8]?[0-9]\.{1}\d+|90\.{1}0+)$/;
    const lonPattern = /^-?((1[0-7][0-9]|0?[0-9]?[0-9])\.{1}\d+|180\.{1}0+)$/;
    return latPattern.test(String(lat)) && lonPattern.test(String(lon));
  };

  const checkAllCoordinates = (coordsArray) => {
    for (let i = 0; i < coordsArray.length; i++) {
      const [lat, lon] = coordsArray[i];
      if (!isValidCoordinates(lat, lon)) {
        return false;
      }
    }
    return true;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const encodedString = e.target.encodedString.value;

    try {
      const decodedCoords = polyline.decode(encodedString);
      const allCoordinatesValid = checkAllCoordinates(decodedCoords);

      if (!allCoordinatesValid) {
        throw new Error("invalid coords");
      }

      setIsError(false);
      context.decodedCoordinates.set(decodedCoords);
      e.target.encodedString.value = "";
      setEncodedString("");
    } catch (error) {
      console.log(error);
      setIsError(true);
      return;
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setEncodedString(e.target.value);
  };

  const handleClearRoute = (e) => {
    e.preventDefault();
    context.map.clearRoute.set(!context.map.clearRoute.value);
  };

  return (
    <div className={styles.string_decoder__menu}>
      <Card style={{ height: "fit-content", width: "fit-content" }}>
        <CardContent>
          <form onSubmit={handleFormSubmit}>
            <TextFormInput
              type="text"
              value={encodedString}
              name="encodedString"
              onChange={handleInputChange}
              placeholder={"Polyline"}
              error={isError}
              errorMessage={isError ? "Invalid polyline" : ""}
            />
            <Button
              style={{
                marginTop: "1rem",
                marginRight: "1rem",
              }}
              type="submit"
              color="primary"
              onChange={handleInputChange}
              disabled={!encodedString}
            >
              Decode polyline
            </Button>
            <Button
              style={{
                marginTop: "1rem",
              }}
              color="secondary"
              startIcon={<Close16 />}
              onClick={handleClearRoute}
            >
              Clear routes
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
