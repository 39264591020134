import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@yunex/yds-react'

export function HamburgerMenu({ menuOpen, setMenuOpen }) {
    HamburgerMenu.propTypes = {
        menuOpen: PropTypes.bool,
        setMenuOpen: PropTypes.func,
    }
    const theme = useTheme().palette.mode

    const handleClick = () => {
        setMenuOpen(!menuOpen)
    }

    const bar = {
        position: 'absolute',
        width: '20px',
        height: '3px',
        backgroundColor: theme === 'light' ? '#252c33' : '#adbdcc',
        transitionDuration: '.1s',
    }

    return (
        <button
            className="hamburgermenu"
            type="button"
            onClick={handleClick}
            style={{
                cursor: 'pointer',
                border: 'none',
                background: 'none',
                backgroundColor: 'transparent',
                width: '30px',
                heigh: '30px',
                WebkitTapHighlightColor: 'transparent',
            }}
        >
            <div
                style={{
                    ...bar,
                    top: '4px',
                    transformOrigin: 'center',
                    transform: menuOpen === true && 'rotate(40deg) translate(-1px, 7px)',
                }}
            />
            <div style={{ ...bar, top: '9px', opacity: menuOpen === true && '0' }} />
            <div
                style={{
                    ...bar,
                    top: '14px',
                    transformOrigin: 'center',
                    transform: menuOpen === true && 'rotate(-40deg) translate(-1px, -7px)',
                }}
            />
        </button>
    )
}
