import React, { useState } from "react";
import { Drawer } from "@yunex/yds-react";
import useWindowSize from "../../../customHooks/windowSize";
import DrawerContent from "./DrawerContent/drawerContent";

export function Draw({}) {
  const [open, setOpen] = useState(true);
  const size = useWindowSize();

  let displayWidth;
  if (open === true && size.width < 850) {
    displayWidth = "Calc(100vw - 1rem)";
  } else if (open === true && size.width < 850) {
    displayWidth = "30vw";
  } else if (open === true && size.width >= 850) {
    displayWidth = "30vw";
  } else {
    displayWidth = "30px";
  }

  return (
    <div className="container">
      <Drawer
        open={open}
        variant="permanent"
        style={{
          width: "auto",
          border: "1px solid red",
        }}
        sx={{
          "& [class*=MuiPaper-root ]": {
            top: "50px",
            position: "fixed",
            height: "Calc(100vh - 75px)", // 25px footer, 50 px header
            width: displayWidth,
            minWidth: open === true && "180px",
            maxWidth: size.width > 650 ? "350px" : "100vw",
            whiteSpace: "normal",
            zIndex: 1050,
            padding: "1.4rem",
          },
        }}
      >
        <DrawerContent />
      </Drawer>
    </div>
  );
}
