import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@yunex/yds-react'
import useWindowSize from '../customHooks/windowSize'

export function PageBanner({ title, paddingSize }) {
    PageBanner.propTypes = {
        title: PropTypes.string,
        paddingSize: PropTypes.string,
    }
    const { palette } = useTheme()
    const size = useWindowSize()

    return (
        <div
            className="titleblock"
            style={{
                position: 'relative',
                width: '100%',
                height: '100px',
                backgroundColor: palette.mode === 'light' ? 'rgba(244, 244, 243, .85)' : 'rgba(37, 44, 51, .7)',
                }}
        >
            <div
                className="titleText"
                style={{
                    position: 'relative',
                    width: 'fit-content',
                    padding: size.width > 650 ? '1rem' : '.5rem',
                    paddingLeft: paddingSize,
                    bottom: '-45px',
                    color: palette.text.primary,
                }}
            >
                {size.width > 650 ? <h2>{title}</h2> : <h3>{title}</h3>}
            </div>
        </div>
    )
}
