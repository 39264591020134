import React, { useContext } from "react";
import { Spinner } from "@yunex/yds-react";
import { Map } from "../components/MapComponents/Map/map";
import { Draw } from "../components/MapComponents/Drawer/drawer";
import GeoDataContext from "../store/geo-data-context";
import useWindowSize from "../customHooks/windowSize";
import Popup from "../components/MapComponents/Popup/popup";
import LocationPinMenu from "../components/MapComponents/LocationPinMenu/locationPinMenu";
import StringDecoder from "../components/MapComponents/StringDecoder/stringDecoder";

export function GeoPage() {
  const context = useContext(GeoDataContext);
  const mapZoom = context.map.zoom;
  const mapCenter = context.map.center;
  const size = useWindowSize();
  const showOk = context.showOk;
  const showError = context.showError;
  const showOffline = context.showOffline;
  const showOv = context.showOv;
  const showVracht = context.showVracht;

  if (!context.geoData || context.geoData.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div
        className="Homepage"
        style={{
          display: "flex",
        }}
      >
        <div
          className="drawer_container"
          style={{
            width: "30vw",
            minWidth: "180px",
            maxWidth: size.width > 650 ? "350px" : "100vw",
            flex: "none",
          }}
        >
          <Draw />
          {context.selectedVri.value && <Popup />}
        </div>
        <div
          className="mapContainer"
          style={{
            zIndex: `0`,
            flex: 1,
          }}
        >
          <Map
            center={mapCenter.value}
            zoom={mapZoom.value}
            showOk={showOk.value}
            showError={showError.value}
            showOffline={showOffline.value}
            showOv={showOv.value}
            showVracht={showVracht.value}
          />
        </div>
      </div>
        <div
          style={{
            position: "absolute",
            zIndex: 500,
            right: 25,
            top: 75,
          }}
        >
          <LocationPinMenu />
          <StringDecoder />
        </div>
    </>
  );
}
