import React, { useState, useEffect } from "react";
import { Button, useTheme } from "@yunex/yds-react";
import useWindowSize from "../customHooks/windowSize";
import MarkdownEditor from "@uiw/react-markdown-editor";
import useIsLoggedIn from "../customHooks/isLoggedIn";
import axios from "axios";
import { uiActions } from "../store/UI/ui-slice";
import { useDispatch } from "react-redux";

let pageId = null;
let pageName = null;

export function Storingen() {
  const dispatch = useDispatch();
  const [markdown, setMarkdown] = useState("");
  const isLoggedIn = useIsLoggedIn();
  useEffect(() => {
    const getContent = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "page?name=v2x",
          {
            headers: {
              "Ocp-Apim-Subscription-Key":
                process.env.REACT_APP_API_PRODUCT_KEY,
            }
          }
        );
        pageId = response.data.id;
        pageName = response.data.name;
        setMarkdown(response.data.content);
      } catch (error) {
        console.log(error);
      }
    };
    getContent();
  }, []);

  function handleSaveChanges(event) {
    event.preventDefault();
    const saveChanges = async () => {
      try {
        const response = await axios.put(
          process.env.REACT_APP_API_URL + "page/create",
          { Id: pageId, Name: pageName, Content: markdown },
          {
            headers: {
              "Ocp-Apim-Subscription-Key":
                process.env.REACT_APP_API_PRODUCT_KEY,
              Authorization: "bearer " + localStorage.getItem("token"),
              ContentType: "application/json",
            },
          }
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            message: "changes saved succesfully",
          })
        );
      } catch (error) {
        dispatch(
          uiActions.showNotification({
            status: "error",
            message: "something went wrong trying to save the changes",
          })
        );
        console.log(error);
      }
    };
    saveChanges();
  }

  return (
    <>
      {!isLoggedIn ? (
        <>
          <MarkdownEditor.Markdown source={markdown} height="100vh" />
        </>
      ) : (
        <>
          <Button onClick={handleSaveChanges}>Save changes</Button>
          <br></br>
          Afbeeldingen eerst converteren naar base64 en daarna importeren als
          img tag
          <MarkdownEditor
            value={markdown}
            height="100vh"
            onChange={(value, viewUpdate) => setMarkdown(value)}
          />
        </>
      )}
    </>
  );
}
