import { Node24, ShapeObject30 } from '@yunex/yds-icons';
import { GisObjectIcon } from '@yunex/yds-react';

export default function HeaderIcon(props) {
  const markerStyle = () => {
    let iconColor;
    let shapeColor;

    if (props.status === 'ok') {
      iconColor = '#FFF';
      shapeColor = '#15d18f';
    } else if (props.status === 'warning') {
      iconColor = '#1c2126';
      shapeColor = '#f7d033';
    } else if (props.status === 'info') {
      iconColor = '#1c2126';
      shapeColor = '#85b6ff';
    } else if (props.status === 'error') {
      iconColor = '#252c33';
      shapeColor = '#e35f00';
    } else if (props.status === 'offline') {
      iconColor = '#252c33';
      shapeColor = '#d0dbe6';
    } else {
      iconColor = '#1c2126';
      shapeColor = '#9c40df';
    }

    return (
      <GisObjectIcon
        icon={Node24}
        iconProps={{
          height: 21,
          width: 21,
          color: iconColor,
        }}
        shape={ShapeObject30}
        shapeProps={{
          color: shapeColor,
          height: 35,
          width: 35,
        }}
        titleProps={{
          component: 'div',
        }}
      />
    );
  };

  return markerStyle();
}
