import React from "react";
import {
  FormControlLabel,
  Switch,
  Theme,
  useSwitchTheme,
  useTheme,
} from "@yunex/yds-react";
import { Sun24 } from "../../../attributes/icons/sun_24 ";
import { Moon24 } from "../../../attributes/icons/moon_24";

export function LightSwitch() {
  const theme = useTheme().palette.mode;
  const switchTheme = useSwitchTheme();
  const handleChange = () => {
    switchTheme(theme === Theme.Light ? Theme.Dark : Theme.Light);
  };

  return (
    <div style={{ width: "24px" }}>
      <div
        style={{
          position: "relative",
          width: "10px",
          height: "fitcontent",
          transform: "translate(3px, 26px)",
          pointerEvents: "none",
        }}
      >
        <Moon24 theme={theme} />
      </div>
      <FormControlLabel
        sx={{ width: "30px" }}
        control={
          <Switch
            checked={theme === Theme.Dark}
            onChange={handleChange}
            sx={{
              position: "relative",
              left: "0px",
              zIndex: "-1",
            }}
          />
        }
      />
      <div
        style={{
          position: "abolute",
          width: "10px",
          height: "fitcontent",
          transform: "translate(16px, -26px)",
          pointerEvents: "none",
        }}
      >
        <Sun24 theme={theme} />
      </div>
    </div>
  );
}
