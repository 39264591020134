import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import {
  ThemeProvider,
  Theme,
  AdapterDayjs,
  LocalizationProvider,
} from "@yunex/yds-react";
import "mapbox-gl/dist/mapbox-gl.css";
import "./attributes/css/main.scss";
import App from "./app";
import store from "./store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={Theme.Light}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <App />
      </LocalizationProvider>
    </ThemeProvider>
  </Provider>
);
