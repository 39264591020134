import React from "react";
import PropTypes from "prop-types";
import { useTheme, Typography } from "@yunex/yds-react";
import { NavLink } from "react-router-dom";
import useWindowSize from "../../customHooks/windowSize";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/UI/ui-slice";
import useIsLoggedIn from "../../customHooks/isLoggedIn";

export function Footer({ selectedIndex, setSelectedIndex }) {
  Footer.propTypes = {
    selectedIndex: PropTypes.number,
    setSelectedIndex: PropTypes.func,
  };
  const dispatch = useDispatch();
  const size = useWindowSize();
  const isLoggedIn = useIsLoggedIn();
  const theme = useTheme().palette.mode;

  const handleItemClick = (index) => {
    setSelectedIndex(index);
  };

  const handleLogin = () => {
    dispatch(uiActions.toggleModal());
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
  };

  const footerText = {
    textDecoration: "none",
    color: "inherit",
    fontWeight: "500",
    fontSize: "10px",
  };

  return (
    <div
      className="footer"
      style={{
        position: "fixed",
        bottom: "0",
        display: "flex",
        flexDirection: size.width > 850 ? "row" : "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: size.width < 850 ? "50px" : "25px",
        width: "100%",
        zIndex: "100",
        paddingRight: "1rem",
        paddingLeft: "1rem",
        paddingTop: "5px",
        backgroundColor: theme === "light" ? "#fff" : "#2f3740",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          className="hoverHref"
          variant="body2"
          gutterBottom
          sx={{
            ...footerText,
            color: selectedIndex === 4 ? "#106ecc" : "inherit",
            fontWeight: selectedIndex === 4 ? "800" : "inherit",
          }}
          component={NavLink}
          to="/overivri"
          onClick={() => handleItemClick(4)}
        >
          About iVRI Portaal
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className="hoverHref"
          style={footerText}
          onClick={() => {
            window.open(
              "https://customerportal.yunextraffic.com/nl-NL/SignIn",
              "_blank",
              "noreferrer"
            );
          }}
        >
          Yunex Servicedesk
        </Typography>
        {isLoggedIn ? (
          <Typography
            variant="body2"
            gutterBottom
            className="hoverHref"
            style={footerText}
            onClick={handleLogout}
          >
            Logout
          </Typography>
        ) : (
          <Typography
            variant="body2"
            gutterBottom
            className="hoverHref"
            style={footerText}
            onClick={handleLogin}
          >
            Login
          </Typography>
        )}
      </div>
      <div
        style={{
          width: size.width > 850 && "560px",
        }}
      >
        <Typography variant="body2" gutterBottom style={footerText}>
          Copyright © 2024 iVRI Portaal. Alle rechten voorbehouden.
        </Typography>
      </div>
    </div>
  );
}
