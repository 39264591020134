import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: { modalOpen: false, notification: null },
  reducers: {
    toggleModal(state) {
      state.modalOpen = !state.modalOpen;
    },
    showNotification(state, action) {
      state.notification = {
        status: action.payload.status,
        message: action.payload.message,
        open: true,
      };
    },
    closeNotification(state) {
      state.notification = { open: false };
    },
    hideNotification(state) {
      state.notification = null;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
