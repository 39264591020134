import React from 'react'
import { StatusPage } from '@yunex/yds-react'

export function ErrorPage() {
    return (
        <div style={{ position: 'relative', top: '50vh', transform: 'translateY(-70%)' }}>
            <StatusPage
                title="Page not found (400)"
                description="Check the URL address and try again."
            />
        </div>
    )
}
