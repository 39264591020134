import React from "react";

const GeoDataContext = React.createContext({
  geoData: [],
  pins: {
    value: null,
    set: (value) => {},
  },
  decodedCoordinates: {
    value: null,
    set: (value) => {},
  },
  selectedVri: {
    value: null,
    set: (value) => {},
  },
  selectedIndex: {
    value: null,
    set: (value) => {},
  },
  showOk: {
    value: null,
    set: (value) => {},
  },
  showError: {
    value: null,
    set: (value) => {},
  },
  showOffline: {
    value: null,
    set: (value) => {},
  },
  showVracht: {
    value: null,
    set: (value) => {},
  },
  showOv: {
    value: null,
    set: (value) => {},
  },
  map: {
    clearRoute: {
      value: null,
      set: (value) => {},
    },
    center: {
      value: null,
      set: (value) => {},
    },
    zoom: {
      value: null,
      set: () => {},
    },
  },
  popup: {
    dialogOpen: {
      value: false,
      set: () => {},
    },
  },
  modal: {
    open: {
      value: false,
      set: () => {},
    },
  },
  focusedItem: {
    value: null,
    set: () => {},
  },
  deselect: {
    value: null,
    set: () => {},
  },
});

export default GeoDataContext;
