import React from "react";
import "./attributes/css/main.scss";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import { AboutIvri } from "./pages/aboutIvriPage";
import { ErrorPage } from "./pages/errorPage";
import { PriotalkerHandleiding } from "./pages/priotalkerHandleidingPage";
import { GeoPage } from "./pages/geoPage";
import { Storingen } from "./pages/storingenPage";
import GeoDataProvider from "./store/GeoDataProvider";
import RootLayout from "./pages/Root";

const router = createBrowserRouter([
  {
    path: "/",
    id: "root",
    element: <RootLayout />,
    children: [
      { index: true, loader: () => redirect("/map") },
      {
        path: "map",
        element: <GeoPage />,
      },
      {
        path: "overivri",
        element: <AboutIvri />,
      },
      {
        path: "storingen",
        element: <Storingen />,
      },
      {
        path: "priotalker",
        element: <PriotalkerHandleiding />,
      },
      // {
      //   path: "login",
      //   element: <Login />,
      // },
      { path: "*", element: <ErrorPage /> },
    ],
  },
]);

export default function App() {
  // const [geoJSON, setGeoJSON] = useState([]);
  // const [menuOpen, setMenuOpen] = useState(false);
  // const [currentVRI, setCurrentVRI] = useState(1);
  // const [filterTLC, setFilterTLC] = useState("");
  // const [filterITS, setFilterITS] = useState("");
  // const [filterRIS, setFilterRIS] = useState("");
  // const [filterOvPrio, setFilterOvPrio] = useState("");
  // const [filterVrachtPrio, setFilterVrachtPrio] = useState("");
  // const [openOk, setOpenOk] = useState(true);
  // const [openInfo, setOpenInfo] = useState(true);
  // const [openError, setOpenError] = useState(true);
  // const [openOffline, setOpenOffline] = useState(true);
  // const [openWarning, setOpenWarning] = useState(true);
  // const [selectedIndex, setSelectedIndex] = useState(1);

  return (
    <GeoDataProvider>
      <RouterProvider router={router} />
    </GeoDataProvider>
  );
}
