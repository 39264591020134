import { Outlet } from "react-router-dom";
import { Header } from "../components/Header/header";
import { Footer } from "../components/Footer/footer";
import Modal from "../components/MapComponents/Modal/Modal";
import { useSelector } from "react-redux";
import Feedback from "../components/Feedback/Feedback";

const RootLayout = () => {
  const notification = useSelector((state) => state.ui.notification);

  return (
    <>
      <Header />
      <main style={{ marginTop: "50px" }}>
        <Outlet />
      </main>
      <Modal />
      {notification && (
        <Feedback
          status={notification.status}
          message={notification.message}
          open={notification.open}
        />
      )}
      <Footer />
    </>
  );
};

export default RootLayout;
