import { Alert, Snackbar } from "@yunex/yds-react";
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/UI/ui-slice";

export default function Feedback(props) {
  const dispatch = useDispatch();
  let severity = props.status === "error" ? "error" : "success";

  const handleClose = () => {
    dispatch(uiActions.closeNotification());
    dispatch(uiActions.hideNotification());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={props.open}
      autoHideDuration={3500}
      onClose={handleClose}
    >
      <Alert severity={severity}>{props.message}</Alert>
    </Snackbar>
  );
}
