import React from 'react'
import { useTheme, Link } from '@yunex/yds-react'
import { PageBanner } from '../components/pageBanner'
import useWindowSize from '../customHooks/windowSize'

export function AboutIvri() {
    const { palette } = useTheme()
    const size = useWindowSize()

    return (
        <div
            className="aboutIvriPage"
            style={{
                position: 'fixed',
                top: '0px',
                backgroundColor: palette.secondary.main,
                height: '100vh',
                width: '100vw',
            }}
        >
            <PageBanner title="Over het iVRI Portaal" paddingSize="10vw" />

            <div
                className="textContainer"
                style={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: '750px',
                    padding: '1.5rem',
                    paddingLeft: '10vw',
                    textAlign: 'justify',
                    height:
                        size.width < 850 ? 'Calc(100% - 22vh - 50px)' : 'Calc(100% - 22vh - 25px)',
                }}
            >
                <h3>
                    <p>
                        Deze website bevat allerhande informatie gerelateerd aan intelligente
                        verkeersregelinstallaties (iVRI's) en wordt beheerd door Yunex Traffic B.V.
                        Het Portaal houdt real-time informatie bij over het status van deze
                        verkeerslichten. Als Talking Traffic gebruiker kunt u hier een melding maken
                        als er een storing op straat is opgetreden. Als wegbeheerder kunt u direct
                        doorklikken naar uitgebreide dashboards van de iVRI's in hun regio als u
                        storingen nader wenst te analyseren. Op deze manier leveren we gezamenlijk
                        publiek-privaat een bijdrage aan de kwaliteit van de integrale Talking
                        Traffic keten
                    </p>
                    <p>
                        Wenst u als wegbeheerder gebruik te maken van gedetailleerde iVRI dashboards
                        of heeft u support vragen? Hiervoor kunt u mailen naar de servicedesk van
                        Yunex Traffic{' '}
                        <Link href="mailto:servicedesk.nl@yunextraffic.com">
                            <h3>servicedesk.nl@yunextraffic.com</h3>
                        </Link>
                    </p>
                </h3>
            </div>
        </div>
    )
}
