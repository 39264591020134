import { useEffect } from "react";
import L from "leaflet";
import {} from "!mapbox-gl";
import {} from "!mapbox-gl-leaflet";
import PropTypes from "prop-types";
import { useMap } from "react-leaflet";

const MapBoxGLLayer = (props) => {
  const map = useMap();

  useEffect(() => {
    const gl = L.mapboxGL(props);
    gl.addTo(map);

    // Force a resize calculation on the map so that Mapbox GL layer correctly repaints its height after it has been added.
    setTimeout(() => map._onResize(), 200);

    // Clean up function
    return () => {
      map.removeLayer(gl);
    };
  }, [map]);

  useEffect(() => {
    const correctCenter = { lat: props.center[0], lng: props.center[1] };
    map.setView(correctCenter, props.zoom);
  }, [map, props.zoom, props.center]);

  return null; // This custom layer doesn't render any React elements, so we return null.
};

MapBoxGLLayer.propTypes = {
  accessToken: PropTypes.string.isRequired,
  style: PropTypes.string,
};

MapBoxGLLayer.defaultProps = {
  style: "mapbox://styles/mapbox/streets-v9",
};

export default MapBoxGLLayer;
