import { Node16, PinObjectOutline34 } from "@yunex/yds-icons";

export default function MapMarker({ status }) {
  const markerStyle = () => {
    let iconColor;
    let shapeColor;

    if (status === "ok") {
      iconColor = "#FFF";
      shapeColor = "#15d18f";
    } else if (status === "warning") {
      iconColor = "#1c2126";
      shapeColor = "#f7d033";
    } else if (status === "info") {
      iconColor = "#1c2126";
      shapeColor = "#85b6ff";
    } else if (status === "error") {
      iconColor = "#252c33";
      shapeColor = "#e35f00";
    } else if (status === "offline") {
      iconColor = "#252c33";
      shapeColor = "#d0dbe6";
    } else {
      iconColor = "#1c2126";
      shapeColor = "#9c40df";
    }

    return (
      <div
        style={{
          display: `flex`,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: `absolute`,
          }}
        >
          <PinObjectOutline34 color={shapeColor} />
        </div>

        <div
          style={{
            position: `absolute`,
            top: -13,
          }}
        >
          <Node16 color={iconColor} />
        </div>
      </div>
    );
  };

  return markerStyle();
}
