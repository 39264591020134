import { ListViewContextProvider } from "@yunex/yds-react-composites";
import NewTrafficController from "./TrafficController/trafficController";

export default function DrawerContent() {
  return (
    <div className="content-container">
      <ListViewContextProvider defaultState={{ isCompactList: true }}>
        <NewTrafficController />
      </ListViewContextProvider>
    </div>
  );
}
