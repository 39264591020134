import React, { useState, useEffect } from "react";
import { Button } from "@yunex/yds-react";
import MarkdownEditor from "@uiw/react-markdown-editor";
import useIsLoggedIn from "../customHooks/isLoggedIn";
import axios from "axios";
import { uiActions } from "../store/UI/ui-slice";
import { useDispatch } from "react-redux";

let pageId = null;
let pageName = null;

export function PriotalkerHandleiding() {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();

  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    const getContent = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "page?name=priotalker",
          {
            headers: {
              "Ocp-Apim-Subscription-Key":
                process.env.REACT_APP_API_PRODUCT_KEY,
            },
          }
        );
        pageId = response.data.id;
        pageName = response.data.name;
        setMarkdown(response.data.content);
      } catch (error) {
        console.log(error);
      }
    };
    getContent();
  }, []);

  function handleSaveChanges(event) {
    event.preventDefault();
    const saveChanges = async () => {
      try {
        const response = await axios.put(
          process.env.REACT_APP_API_URL + "page/create",
          { Id: pageId, Name: pageName, Content: markdown },
          {
            headers: {
              "Ocp-Apim-Subscription-Key":
              process.env.REACT_APP_API_PRODUCT_KEY,
              Authorization: "bearer " + localStorage.getItem("token"),
              ContentType: "application/json",
            },
          }
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            message: "changes saved succesfully",
          })
        );
      } catch (error) {
        dispatch(
          uiActions.showNotification({
            status: "error",
            message: "something went wrong trying to save the changes",
          })
        );
        console.log(error);
      }
    };
    saveChanges();
  }

  return (
    <>
      {!isLoggedIn ? (
        <>
          <MarkdownEditor.Markdown source={markdown} height="100vh" />
        </>
      ) : (
        <>
          <Button onClick={handleSaveChanges}>Save changes</Button>
          <br></br>
          Afbeeldingen eerst converteren naar base64 en daarna importeren als
          img tag
          <MarkdownEditor
            value={markdown}
            height="100vh"
            onChange={(value, viewUpdate) => setMarkdown(value)}
          />
        </>
      )}
    </>
  );

  // return (
  //     <div
  //         className="priotalkerHandleidingPage"
  //         style={{
  //             position: 'fixed',
  //             top: '0px',
  //             backgroundColor: palette.secondary.main,
  //             height: '100vh',
  //             width: '100vw',
  //         }}
  //     >
  //         <div style={{ position: 'absolute', zIndex: 10, width: '100%' }}>
  //             <PageBanner title="Priotalker Handleiding" paddingSize="10vw" />
  //         </div>

  //         <div
  //             className="textContainer"
  //             style={{
  //                 position: 'relative',
  //                 width: '100%',
  //                 padding: '1.5rem',
  //                 paddingTop: '140px',
  //                 paddingLeft: '10vw',
  //                 paddingRight: '10vw',
  //                 textAlign: 'justify',
  //                 height: '100vh',
  //                 overflowY: 'scroll',
  //             }}
  //         >
  //             <h4 style={{ textAlign: 'justfy' }}>
  //                 <div style={{ marginBottom: '60px' }}>
  //                     <p>
  //                         <h3>
  //                             <i>Doel</i>
  //                         </h3>
  //                         <br />
  //                         Priotalker is ontwikkeld als testtool voor het gebruik van intelligente
  //                         verkeersregelinstallaties (iVRI) in Nederland. Met dit tool kan getoetst
  //                         worden of de keten en de verschillende use cases “informeren”,
  //                         “optimaliseren” en “prioriteren” naar behoren werken. Dit tool wordt
  //                         o.a. gebruikt voor de afnametesten van de iVRI's. Omdat het ongewenst
  //                         is, dat 'gewone' weggebruikers verschillende rollen aannemen, is dit
  //                         tool momenteel alleen in een gesloten android release beschikbaar. Als
  //                         wegbeheerder of iVRI leverancier kunt u een aanvraag indienen om de app
  //                         te mogen downloaden. Na toestemming van de regiocoördinator zal u een
  //                         download link ontvangen voor de installatie van de app.
  //                     </p>
  //                     <p>
  //                         Aanmelden voor de priotalker app kunt u doen via
  //                         servicedesk.nl@yunextraffic.com
  //                     </p>
  //                     <p>
  //                         <h3>
  //                             <i>Instellingen</i>
  //                         </h3>
  //                         <br />
  //                         Met Priotalker kunt u diverse voertuigrollen aannemen, indien de
  //                         betreffende rechten toegekend zijn*. De rollen zijn in te stellen door
  //                         met uw vinger op het 2 logo (default een groene auto) onder in het
  //                         scherm te tappen. Het afgebeelde menu verschijnt met een instelkeuze
  //                         voor de gewenste voertuigrol. Tap een keer buiten het menu op het scherm
  //                         om terug te gaan naar het hoofdscherm. Afhankelijk van de geselecteerde
  //                         rol wordt nu een bijpassend icoontje getoond.
  //                         <br />
  //                         <h5>*: Zie hiervoor het artikel'Actieve modus'.</h5>
  //                     </p>
  //                     <p>
  //                         Er zijn 4 groepen van rollen (zie verderop voor de gedetailleerde
  //                         beschrijvingen):
  //                         <ul style={{ margin: 0 }}>
  //                             <li>
  //                                 geen CAM / geen prioriteit (passenger no CAM): voornamelijk
  //                                 bedoelt als u de werking van het licht wil zien, zonder de
  //                                 regeling op straat te beïnvloeden.
  //                             </li>
  //                             <li>
  //                                 alleen verzenden van CAM berichten (passenger with CAM, bicycle)
  //                             </li>
  //                             <li>
  //                                 voertuigen met absolute prioriteit met een aanvraag op een
  //                                 approach (emergency with priority, firebrigade)
  //                             </li>
  //                             <li>
  //                                 prioriteit op een connectie (alle overige voertuigsoorten). Aan
  //                                 de hand van de knipperlichten wordt bepaald op welke connectie
  //                                 prioriteit aangevraagd wordt. Als de stand van de knipperlichten
  //                                 niet overeenkomt met de toegestane maneuvers, zal het systeem
  //                                 een “noRoute” als SSM status teruggeven.
  //                             </li>
  //                         </ul>
  //                     </p>
  //                 </div>
  //                 <div style={{ marginBottom: '60px' }}>
  //                     <img
  //                         src={img01}
  //                         alt="mobiel die een lijst met voertuigrollen toont."
  //                         style={{
  //                             position: 'relative',
  //                             width: '20vw',
  //                             minWidth: '220px',
  //                             float: size.width > 550 && 'right',
  //                             marginLeft: size.width > 800 ? '60px' : '20px',
  //                             marginBottom: '10px',
  //                             marginTop: '24px',
  //                             transform: size.width < 550 && 'translateX(-75%)',
  //                             left: size.width < 550 && '50vw',
  //                         }}
  //                     />
  //                     {size.width < 550 && <br />}
  //                     De voertuigrollen zijn:
  //                     <ul style={{ margin: 0 }}>
  //                         <li>
  //                             <i>Passenger with CAM:</i> Personen-auto die floating car data
  //                             berichten genereert in CAM formaat. Met die berichten kan het
  //                             kruispunt-regel-programma van de nabij-zijnde iVRI u geanonimiseerd
  //                             zien naderen (Use Case 5).
  //                         </li>
  //                         <li>
  //                             <i>Passenger no CAM:</i> U rijdt incognito, er worden geen CAM
  //                             berichten naar de nabije iVRI gestuurd (gesymboliseerd door een
  //                             spookje). U krijgt zowel gewone lantaarns, als ook negenogen te
  //                             zien.
  //                         </li>
  //                         <li>
  //                             <i>Ergency with priority:</i> u bent een nood-hulp-dienst voertuig
  //                             (ambulance, rol emergency “Nooddienst”, subrole5 blueLightAndSiren)
  //                             dat blauw zwaailicht en sirene voert. U stuurt prioriteits-verzoeken
  //                             met SRM berichten (op een approach) naar de nabije iVRI
  //                             stroomafwaarts (Use Case 3b - absolute prioriteit). U krijgt tijdig,
  //                             geforceerd groen op alle signaalgroepen van de arm waarop u rijdt.
  //                             Icoon: blauw zwaailicht.
  //                         </li>
  //                         <li>
  //                             <i>Bus with priority:</i> u bent een openbaar vervoer voertuig
  //                             (vehicleRole publicTransport “Openbaar vervoer”, subrole1 bus) dat
  //                             met SRM berichten prioriteit vraagt aan de nabije iVRI
  //                             stroomafwaarts (Use Case 3a - conditionele prioriteit). U krijgt
  //                             groen als het kan. Vanaf versie 0.0.94 van de app wordt tevens het
  //                             lijnnummer van de bus gevraagd.
  //                         </li>
  //                         <li>
  //                             <i>Truck:</i> u bent een bezorg-vrachtwagen (vehicleRole commercial
  //                             “Commercieel”, t/m versie 99 van de app met subRole12 ecoDriving,
  //                             daarna is het zonder subRole). Ook Use Case 3a - conditionele
  //                             prioriteit, met een andere voertuigklasse dan in bullet 4.
  //                         </li>
  //                         <li>
  //                             <i>Heavy truck:</i> u bent een zware vrachtwagen (vehicleRole
  //                             specialTransport “Speciaal transport”). Weer Use Case 3a -
  //                             conditionele prioriteit, met een andere voertuigklasse dan in
  //                             bullets 4 en 5.
  //                         </li>
  //                         <li>
  //                             <i>Dangerous goods:</i> u bent een vrachtwagen met gevaarlijke
  //                             stoffen (vehicleRole dangerousGoods “Gevaarlijk transport”).
  //                             Andermaal Use Case 3a -conditionele prioriteit, met een andere
  //                             voertuigklasse dan in bullets 4, 5 en 6.
  //                         </li>
  //                         <li>
  //                             <i>Bicycle:</i> u bent een fiets. Als fiets wordt er geen prioriteit
  //                             via een SRM aangevraagd, maar kan het verkeerslicht wel eerder groen
  //                             geven op basis van uw CAM berichten. U krijgt de fietslichten op de
  //                             betreffende arm te zien.
  //                         </li>
  //                         <li>
  //                             <i>Tram:</i> u bent een tram en krijgt de specifieke verkeerslichten
  //                             voor de tram te zien. Tevens wordt er prioriteit op een connectie
  //                             aangevraagd (vehicleRole publicTransport “Openbaar vervoer”,
  //                             subrole2 tram). Vanaf versie 0.0.101 van de app wordt tevens het
  //                             lijnnummer van de tram gevraagd.
  //                         </li>
  //                         <li>
  //                             <i>Emergency with route:</i> u bent een nood-hulp-dienst voertuig
  //                             (ambulance, rol emergency “Nooddienst”, subrole5 blueLightAndSiren)
  //                             dat blauw zwaailicht en sirene voert. U stuurt prioriteits-verzoeken
  //                             met SRM berichten (op een connection op basis van uw knipperlicht
  //                             stand) naar de nabije iVRI stroom-afwaarts (Use Case 3b - absolute
  //                             prioriteit). Icoon: blauw zwaailicht met een rode pijl.
  //                         </li>
  //                         <li>
  //                             <i>Firebrigade:</i> u bent een nood-hulp-dienst voertuig (brandweer,
  //                             rol emergency “Nooddienst”, subrole5 blueLightAndSiren “Noodgeval”)
  //                             dat blauw zwaailicht en sirene voert. Icoon: logo brandweer NL.
  //                         </li>
  //                         <li>
  //                             <i>Remotely defined:</i> op onze backend kunnen wij ook andere
  //                             profielen (inclusief route informatie) instellen. Om dit te
  //                             realiseren hebben wij uw device-ID en het gewenste profiel nodig.
  //                             Dit kunt u doorgeven via de Helpdesk. Nadat wij de gewenste
  //                             informatie hebben geladen, kunt u dit profiel gebruiken.
  //                         </li>
  //                         <li>
  //                             <i> Weginspecteur met OGS:</i> u bent een weginspecteur voertuig
  //                             (rol roadOperator”Wegenbeheer”, subrole5 blueLightAndSiren
  //                             “Noodgeval”) dat blauw zwaailicht en sirene voert.
  //                         </li>
  //                         <li>
  //                             <i>Route zonder prio:</i> op basis van de stand van de
  //                             knipperlichten wordt een SRM0 verzonden zodra het voertuig zich op
  //                             het MAP gebied bevindt en de verwachte reistijd tot de stoplijn
  //                             kleiner dan 120 seconden is.
  //                         </li>
  //                         <li>
  //                             <i>Politie:</i> u bent een politie voertuig (rol safetyCar
  //                             “Verkeersveiligheid”, subrole5 blueLightAndSiren “Noodgeval”) dat
  //                             blauw zwaailicht en sirene voert. Deze rol is beschikbaar vanaf
  //                             versie 111 van de app.
  //                         </li>
  //                         <li>
  //                             <i>Bergingsvoertuig:</i> u bent een weginspecteur voertuig (rol
  //                             rescue”Hulpdienst”, subrole5 blueLightAndSiren “Noodgeval”) dat
  //                             blauw zwaailicht en sirene voert. Deze rol is beschikbaar vanaf
  //                             versie 111 van de app.
  //                         </li>
  //                         <li>
  //                             <i>Militaire hulpdienst:</i> u bent een weginspecteur voertuig (rol
  //                             military “Militair”, subrole5 blueLightAndSiren “Noodgeval”) dat
  //                             blauw zwaailicht en sirene voert. Deze rol is beschikbaar vanaf
  //                             versie 111 van de app.
  //                         </li>
  //                     </ul>
  //                 </div>

  //                 <div
  //                     style={{
  //                         marginBottom: '60px',
  //                         minHeight: '40vw',
  //                     }}
  //                 >
  //                     <img
  //                         src={img02}
  //                         alt="screenshot van de PriotalkerApp met bruine achtergrond."
  //                         style={{
  //                             position: 'relative',
  //                             width: '18vw',
  //                             minWidth: '220px',
  //                             float: size.width > 550 && 'left',
  //                             marginRight: size.width > 800 ? '60px' : '20px',
  //                             marginTop: '5px',
  //                             transform: size.width < 550 && 'translateX(-75%)',
  //                             left: size.width < 550 && '50vw',
  //                             borderRadius: '7px',
  //                         }}
  //                     />
  //                     {size.width < 550 && <br />}
  //                     De App werkt automatisch tegen de <i>TLEX</i> production omgeving. Daarnaast
  //                     kan Priotalker ook draaien tegen lab iVRI's die gekoppeld zijn aan het TLEX
  //                     domein acceptance. Dit kunt u activeren door op het meest rechter icoon
  //                     onderaan het scherm (talking traffic logo) en de optie “staging” te
  //                     selecteren. Om duidelijk te maken dat u op staging draait wordt de
  //                     achtergrond van het scherm nu een bruine tint.
  //                     <br />
  //                     Vanaf versie 107 is het ook mogelijk om het testbed te kiezen als omgeving.
  //                     Let op: via de resource manager moet eerst de Yunex cloud geselecteerd zijn,
  //                     om daadwerkelijk verbinding met het testbed te hebben.
  //                     <br />
  //                     <p>
  //                         <h3>
  //                             <i>Bestemming opgeven</i>
  //                         </h3>
  //                         <br />
  //                         Vanaf versie 108 is het ook mogelijk om een bestemming als latitude /
  //                         longitude op te geven door op het Yunex logo te klikken. Let op: de
  //                         route wordt berekend vanaf de huidige locatie. Dit betekent dat u bij
  //                         gesimuleerde ritten eerst het voertuig op de gewenste startpunt moet
  //                         positioneren en daar moet pauzeren, alvorens de bestemming in te voeren.
  //                         Als tijdens het rijden geconstateerd wordt, dat het voertuig niet (meer)
  //                         op de route rijdt (wordt ook op het scherm getoond), zal hoogstens 1x
  //                         per minuut een nieuwe route berekend worden vanaf het nieuwe startpunt.
  //                         Een berekende route wordt intern gedurende 12 uur opgeslagen op basis
  //                         van het opgegeven eindpunt, dus zolang de bestemming niet wijzigt kan
  //                         dezelfde route meermaals gereden worden. Om weer 'gewoon' zonder route
  //                         te rijden, moet u nogmaals op het Yunex logo klikken en de bestemming
  //                         uit het display verwijderen en daarna op OK klikken.
  //                     </p>
  //                     <p>
  //                         <h3>
  //                             <i>Gebruik vanaf kantoor</i>
  //                         </h3>
  //                         <br />
  //                         Op android telefoons is het mogelijk om een GPS spoofing app te
  //                         installeren zoals bijvoorbeeld lockito. Zie ook de handleiding voor het
  //                         gebruik van lockito op deze website. Let op: als u tegen de productie
  //                         omgeving draait, heeft elke actie direct impact op de
  //                         verkeersafhandeling op straat!
  //                     </p>
  //                     <p>
  //                         <h3>
  //                             <i>Gebruik in de auto</i>
  //                         </h3>
  //                         <br />
  //                         <ul
  //                             style={{
  //                                 margin: 0,
  //                                 transform: 'translateX(40px)',
  //                                 paddingRight: '40px',
  //                             }}
  //                         >
  //                             <li>Volg bovenstaande veiligheids-instructies!</li>
  //                             <li>Zorg dat de Smartphone:</li>
  //                             <ul
  //                                 style={{
  //                                     margin: 0,
  //                                     transform: 'translateX(40px)',
  //                                     paddingRight: '40px',
  //                                 }}
  //                             >
  //                                 <li>locatiegegevens via GPS kan ontvangen.</li>
  //                                 <li>verbonden is met Internet via 4G/LTE.</li>
  //                                 <li>Verbonden is met WiFi voor een betere locatiebepaling.</li>
  //                                 <li>Activeer de App voordat u gaat rijden.</li>
  //                             </ul>
  //                             <li>
  //                                 Standaard staat de App ingesteld als 'passenger with CAM' wat
  //                                 betekent dat u een personen-wagen bent die floating car data
  //                                 (CAM) genereert. Bekijk het hoofdstuk 'Instellingen' om een
  //                                 andere voertuig-rol aan te nemen.
  //                             </li>
  //                         </ul>
  //                     </p>
  //                 </div>
  //                 <div style={{ marginBottom: '60px' }}>
  //                     <img
  //                         src={img03}
  //                         alt="screenshot van de PriotalkerApp met het intepretatie scherm."
  //                         style={{
  //                             position: 'relative',
  //                             width: '18vw',
  //                             minWidth: '220px',
  //                             float: size.width > 550 && 'right',
  //                             marginLeft: size.width > 800 ? '60px' : '20px',
  //                             marginTop: '30px',
  //                             transform: size.width < 550 && 'translateX(-75%)',
  //                             left: size.width < 550 && '50vw',
  //                             borderRadius: '7px',
  //                         }}
  //                     />
  //                     {size.width < 550 && <br />}
  //                     <p>
  //                         <h3>
  //                             <i>Interpretatie Scherm</i>
  //                         </h3>
  //                         <ul style={{ margin: 0 }}>
  //                             <li>
  //                                 Tijdens het rijden ziet u een donker scherm (donkergrijs voor
  //                                 productie, bruin voor acceptance). Als u een iVRI nadert zult u
  //                                 zodra u op het MAP gebied bent de contouren zien van de
  //                                 lantaarns van de arm waarop u rijdt, waarbij in principe per
  //                                 rijstrook een lantaarn getoond wordt (negenogen worden naast
  //                                 elkaar getoond). De juiste arm wordt geselecteerd op basis van
  //                                 de voertuigkarakteristieken en de positie / heading. Vanaf
  //                                 versie 0.94 worden ook rijstroken zonder lantaarns getoond met
  //                                 pijlen die de toegestane maneuvers van die rijstrook tonen. Als
  //                                 de App de SPaT data ontvangen zullen de huidige fasen en de
  //                                 aftellers (seconden) tot de faseovergangen getoond worden. De
  //                                 getoonde data worden gegenereerd door de kruispuntregeling van
  //                                 de iVRI stroomafwaarts. Als er fasen of aftellers ontbreken dan
  //                                 zitten die niet in de data die door de Yunex Traffic backend is
  //                                 ontvangen.
  //                             </li>
  //                             <br />
  //                             <li>
  //                                 De tellers zullen normaal gesproken de verwachte tijd tot het
  //                                 einde van de huidige fase weergeven (likelyTime uit het SPaT
  //                                 bericht), mits dit niet verder dan 60 seconden in de toekomst
  //                                 ligt. Als bovendien de minEndTime en maxEndTime binnen 1 seconde
  //                                 van de likelyTime af liggen, is de VRI erg zeker dat dit de
  //                                 juiste tijd is en wordt deze in witte cijfers getoond. Is deze
  //                                 zekerheid er niet, worden de aftellers in roze weergegeven. Als
  //                                 de likelyTime niet beschikbaar is, maar de minEndTime en
  //                                 maxEndTime binnen een seconde van elkaar liggen, wordt dit
  //                                 weergegeven door het gemiddelde van beide getallen vooraf te
  //                                 laten gaan door een “=” teken. Als dit niet het geval is, zal
  //                                 gekeken worden of er een minimum geldige tijd bekend is voor de
  //                                 huidige fase (minEndTime). In dit geval zal de teller
  //                                 voorafgegaan worden door een 'groter dan' ofwel '&gt;'' teken.
  //                                 Als ook deze informatie niet beschikbaar is, wordt uiteindelijk
  //                                 getoetst of er een maximum tijd bekend is (maxEndTime). Als dit
  //                                 zo is wordt deze gevisualiseerd met een 'kleiner dan' ofwel
  //                                 '&lt;' teken.
  //                             </li>
  //                             <br />
  //                             <li>
  //                                 Hoe een lantaarn getoond wordt hangt af van de gedefinieerde
  //                                 maneuvres en de eventState. Er wordt een roze vraagteken getoond
  // 								als de VRI in het intersectionStatusObject aangeeft dat er geen
  // 								valide SPaT beschikbaar is, of als de eventState unavailable is.
  // 								Er wordt alleen een pijl getoond als
  //                                 er een protected movement of rood is en er niet meerdere
  //                                 manoeuvres zijn toegestaan. In alle andere gevallen wordt een
  //                                 volle lens getoond. Er wordt een negenoog getoond als
  //                                 AllowedVehicleTypes wel een bus / tram, maar geen passengerCar
  //                                 toestaat. Bij een permissive movement/clearance bij een negenoog
  //                                 wordt deze knipperend weergegeven. Er wordt een zwarte lantaarn
  //                                 getoond als er geen SPaT data in de afgelopen 1,5 seconden is
  //                                 binnengekomen. De Yunex Traffic backend maakt elke seconde een
  //                                 SPaT bericht aan als er in de afgelopen 10 seconden tenminste
  //                                 één SPaT van de iVRI is ontvangen. Dit betekent dat 11,5
  //                                 seconden na het wegvallen van de SPaT stroom van TLEX pas een
  //                                 zwarte lantaarn getoond wordt. Als de communicatie tussen de App
  //                                 en de Yunex Traffic backend wegvalt wordt dit binnen 1,5
  //                                 seconden zichtbaar.
  //                             </li>
  //                             <br />
  //                             <li>
  //                                 Onder een lantaarn wordt de movementName getoond als deze
  //                                 gedefinieerd is in het valide SPaT bericht én een lengte heeft
  //                                 tussen de 1 en 5 karakters. Anders wordt de signalId getoond
  //                                 voorafgegaan door een “#”.
  //                             </li>
  //                             <br />
  //                             <li>
  //                                 In het scherm worden linksboven het midden in tekst getoond:
  //                                 <ul style={{ margin: 0 }}>
  //                                     <li>Distance: afstand tot de stopstreep in meters.</li>

  //                                     <li>
  //                                         Update: tijdstempel (na correctie voor de offset van de
  //                                         Smartphone met de GPS tijd) wanneer de iVRI informatie
  //                                         voor het van laatst op het scherm is aangepast, hetzij
  //                                         omdat er een SPaT bericht is ontvangen, of er is
  //                                         sindsdien 1 of meer seconden verstreken.
  //                                     </li>

  //                                     <li>
  //                                         WhyWait: de reden wachttijd uit het SPaT bericht, die op
  //                                         1 of meer signaalgroepen voor deze approach gemeld
  //                                         wordt. Normaal wordt deze gezet als er aan een
  //                                         prioriteits-voertuig op een conflicterende richting
  //                                         prioriteit wordt verleend. In het voorbeeld hierboven
  //                                         stuurt de VRI een “unknown” als status uit. In dit veld
  //                                         kan ook informatie uit het intersectionStatusObject
  //                                         getoond worden:
  //                                         <ul style={{ margin: 0 }}>
  //                                             <li>
  //                                                 VRI no valid SPAT available. Dit wordt getoond
  //                                                 als de VRI in het IntersectionStatusObject het
  //                                                 bit 13 “noValidSPATisAvailableAtThisTime” hoog
  //                                                 heeft staan
  //                                             </li>

  //                                             <li>
  //                                                 VRI turned off: Dit wordt getoond als de VRI in
  //                                                 het IntersectionStatusObject het bit 9 “off”
  //                                                 hoog heeft staan
  //                                             </li>

  //                                             <li>
  //                                                 VRI FailureMode: Dit wordt getoond als de VRI in
  //                                                 het IntersectionStatusObject het bit 8
  //                                                 “failureMode” hoog heeft staan VRI FailureFlash:
  //                                                 Dit wordt getoond als de
  //                                             </li>

  //                                             <li>
  //                                                 VRI in het IntersectionStatusObject het bit 2
  //                                                 “failureFlash” hoog heeft staan
  //                                             </li>
  //                                         </ul>
  //                                     </li>
  //                                 </ul>
  //                             </li>
  //                             <br />
  //                             <li>
  //                                 In het scherm rechtsboven het midden in tekst worden getoond
  //                                 <ul style={{ margin: 0 }}>
  //                                     <li>
  //                                         Intersectie ID + Arm ID: De unieke ID van de intersectie
  //                                         waar u op af rijdt (bestaande uit regio en id in
  //                                         decimale representatie) aangevuld met de Approach / Arm
  //                                         waarop u de iVRI nadert
  //                                     </li>
  //                                     <li>
  //                                         dt: bestaat uit 2 delen (sinds versie 76): het eerste
  //                                         deel laat de geschatte klok afwijking van de iVRI zien
  //                                         in milliseconden, het tweede deel laat het tijdverschil
  //                                         in ms zien tussen het moment van genereren van de SPaT
  //                                         (moy/timestamp en correctie van de geschatte
  //                                         klokafwijking van de iVRI) en het ontvangstmoment op de
  //                                         Smartphone. Als dit een negatief getal oplevert, loopt
  //                                         de klok van de iVRI blijkbaar voor op de GPS tijd. In
  //                                         het eerste deel hebben we gezien dat hier een zeer groot
  //                                         getal staat, omdat de VRI blijkbaar lokale tijd i.p.v.
  //                                         UTC tijd stuurt, of dat de synchronisatie ontbreekt. Als
  //                                         de tijdsynchronisatie van de Smartphone met de GPS/NTP
  //                                         tijd niet gelukt is wordt de tekst in een gele kleur
  //                                         i.p.v. in wit getoond.
  //                                     </li>
  //                                     <li>
  //                                         SSM: de SSM status zoals door de VRI gerapporteerd. Als
  //                                         er een SSM ontvangen wordt voor een intersectie, die
  //                                         afwijkt van de huidige SPaT stroom dan wordt de SSM
  //                                         status voorafgegaan door de intersectie ID waar het
  //                                         betrekking op heeft. Naast de standaard SSM statussen
  //                                         vanuit de VRI kunnen ook additionele statussen door de
  //                                         Yunex Traffic cloud gegenereerd worden:
  //                                         <ul style={{ margin: 0 }}>
  //                                             <li>
  //                                                 cancelled: na het passeren van de stopstreep
  //                                             </li>
  //                                             <li>
  //                                                 noAnswer: de VRI heeft geen antwoord binnen 1
  //                                                 seconde na verzending van het SRM-verzoek
  //                                                 gestuurd
  //                                             </li>
  //                                             <li>
  //                                                 wrongSeqNo: de VRI heeft binnen 1 seconde na
  //                                                 verzending van het SRM-verzoek een SSM
  //                                                 verzonden, echter deze bevatte een onjuist
  //                                                 sequenceNumber
  //                                             </li>
  //                                             <li>
  //                                                 maxEtaExceeded: de estimated time of arrival
  //                                                 (ETA) ontbreekt of ligt verder in de toekomst
  //                                                 dan toegestaan (&lt; 5 minuten).
  //                                             </li>
  //                                             <li>
  //                                                 noRoute: er kan geen maneuver gevonden worden
  //                                                 passend bij de huidige knipperlicht stand /
  //                                                 route info en de aangegeven permissies in het
  //                                                 MAP bestand.
  //                                             </li>
  //                                             <li>
  //                                                 noPbcMatch: bij de huidige knipperlicht stand /
  //                                                 route en voertuiginfo, is geen PBC regel
  //                                                 aanwezig waar dit voertuig volledig aan voldoet.
  //                                                 In deze situatie wordt op productie geen SRM
  //                                                 verzonden (op acceptatie wel, maar dan met
  //                                                 importanceLevel unknown).
  //                                             </li>
  //                                         </ul>
  //                                     </li>
  //                                 </ul>
  //                             </li>
  //                             <br />
  //                             <li>
  //                                 Onder deze 2 blokken kan ook een technische foutmelding
  //                                 verschijnen:
  //                                 <ul style={{ margin: 0 }}>
  //                                     <li>
  //                                         Error 404: Could not retrieve dynamic traffic
  //                                         information. Dit betekent dat de GPS locatie inclusief
  //                                         heading niet op een rijstrook gemapped kon worden. Dit
  //                                         kan o.a. gebeuren als hij/zij zich niet op een normale
  //                                         rijbaan, of zich niet in de normale rijrichting beweegt
  //                                     </li>
  //                                     <li>
  //                                         PrioError of SpatError: doorgaans ontstaan deze omdat er
  //                                         netwerk problemen zijn, of dat netwerk poort 8555 op uw
  //                                         netwerk geblokkeerd wordt.
  //                                     </li>
  //                                     <li>
  //                                         Error 204: GPS error: no location updates. Deze melding
  //                                         wordt gegenereerd als er gedurende 5 seconden geen
  //                                         locatie update is ontvangen. Dit gebeurt vaak als je in
  //                                         een gebouw de app aanzet en nog geen lockito simulatie
  //                                         gestart hebt. Deze melding zou na het starten van een
  //                                         (gesimuleerde) rit moeten verdwijnen. Als dit niet het
  //                                         geval is, moet je controleren dat Priotalker de
  //                                         machtiging heeft om locatie informatie te gebruiken.
  //                                     </li>
  //                                 </ul>
  //                             </li>
  //                         </ul>
  //                     </p>
  //                 </div>
  //             </h4>
  //         </div>
  //     </div>
  // )
}
