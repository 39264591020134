import { StatusInfo16 } from "@yunex/yds-icons";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@yunex/yds-react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../../store/UI/ui-slice";
import React, { useState } from "react";
import axios from "axios";

export default function Modal() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const open = useSelector((state) => state.ui.modalOpen);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // const response = await axios.post(
      //   process.env.REACT_APP_API_URL + "user/authenticate",
      //   { username: username, password: password },
      //   {
      //     headers: {
      //       "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_PRODUCT_KEY,
      //       "Content-Type": "application/json"
      //     },
      //     withCredentials: true
      //   }
      // );
      // console.log(response);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "user/authenticate",
        {
          method: "POST",
          headers: {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_PRODUCT_KEY,
            "Content-Type": "application/json"
          },
          // credentials: 'include',
          body: JSON.stringify({ username: username, password: password })
        }
      );
      
      const data = await response.json();
      localStorage.setItem("token", data.token);
      dispatch(
        uiActions.showNotification({
          status: "success",
          message: "login successfull",
        })
      );
      dispatch(uiActions.toggleModal());
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="dialog-title"
        //fullScreen={isDialogFullScreen}
        onClose={() => dispatch(uiActions.toggleModal())}
        open={open}
      >
        <DialogTitle
          icon={StatusInfo16}
          iconProps={{
            sx: {
              fill: "blue",
            },
          }}
        >
          Login form
        </DialogTitle>
        <DialogContent>
          <form>
            <div>
              <label>Username:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </form>
          {error && <p>{error}</p>}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            //disabled={isSubmitting || isConfirming}
            type="submit"
            onClick={handleSubmit}
          >
            Login
          </Button>
          <Button
            color="secondary"
            //disabled={isSubmitting || isConfirming}
            onClick={() => dispatch(uiActions.toggleModal())}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
