import React, { useContext, useState } from "react";
import "../../../attributes/css/cluster.css";
import ReactDOMServer from "react-dom/server";
import { MapContainer, Marker, Popup } from "react-leaflet";
import { PinObjectOutline12, Remove16, Trash16 } from "@yunex/yds-icons";
import { Button } from "@yunex/yds-react";
import { v4 as uuid } from "uuid";
import MarkerClusterGroup from "react-leaflet-cluster";
import "!mapbox-gl-leaflet";
// import "leaflet/dist/leaflet.css";
import MapMarker from "./MapMarker/mapMarker";
import GeoDataContext from "../../../store/geo-data-context";
import "./map.scss";
import { useEffect, useRef } from "react";
import TileLayers from "./TileLayer/tileLayer";
import styles from "./map.module.scss";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export function Map({
  center,
  zoom,
  showError,
  showOffline,
  showOk,
  showOv,
  showVracht,
}) {
  const context = useContext(GeoDataContext);
  const geoJSON = context.geoData;
  const mapRef = useRef();
  const statusOptions = ["ok", "warning", "info", "error", "offline"];
  const [routeLayer, setRouteLayer] = useState(L.featureGroup());
  const [wayPointLayer, setWayPointLayer] = useState(L.featureGroup());

  useEffect(() => {
    // don't remove this if statement, infinite loop will be created otherwise
    if (
      routeLayer &&
      wayPointLayer &&
      context.decodedCoordinates.value.length > 0
    ) {
      const map = mapRef.current;

      map.removeLayer(routeLayer);
      map.removeLayer(wayPointLayer);
      const latLngs = context.decodedCoordinates.value.map((coords) =>
        L.latLng(coords[0], coords[1])
      );

      // routeLayer.clearLayers();
      // wayPointLayer.clearLayers();

      const polylineLayer = L.polyline(latLngs, { color: "red" });
      // routeLayer.clearLayers();
      routeLayer.addLayer(polylineLayer);

      context.decodedCoordinates.value.forEach((coords, index) => {
        wayPointLayer.addLayer(
          L.marker([coords[0], coords[1]], {
            icon: L.divIcon({
              html: ReactDOMServer.renderToString(
                <PinObjectOutline12 color={index === 0 ? "red" : ""} />
              ),
              className: "dummy",
            }),
          })
        );
      });

      routeLayer.addTo(map);
      wayPointLayer.addTo(map);

      map.fitBounds(polylineLayer.getBounds());
      context.decodedCoordinates.set([]); // will stop useEffect hook getting triggered when other states update
    }
  }, [context.decodedCoordinates, routeLayer, wayPointLayer]);

  useEffect(() => {
    if (routeLayer && wayPointLayer) {
      routeLayer.clearLayers();
      wayPointLayer.clearLayers();
    }
  }, [context.map.clearRoute.value]);

  const handleOnClick = (key) => {
    context.deselect.set(!context.deselect.value);
    const vri = geoJSON.filter((i) => {
      const itemKey = i.properties.Name + "-" + i.properties.UdapName;
      return itemKey === key;
    })[0];
    context.selectedVri.set(vri);
    if (context.popup.dialogOpen.value == false) {
      context.popup.dialogOpen.set(true);
    }
  };

  const createClusterCustomIcon = (status) =>
    function (cluster) {
      return L.divIcon({
        key: "uuid",
        iconSize: [50, 50],
        html: `<span>${cluster.getChildCount()}</span>`,
        className:
          status === "ok"
            ? "clusterSpan okCluster"
            : status === "warning"
            ? "clusterSpan warningCluster"
            : status === "info"
            ? "clusterSpan infoCluster"
            : status === "offline"
            ? "clusterSpan offlineCluster"
            : "clusterSpan errorCluster",
      });
    };

  // GeoMarker
  const geoMarker = (
    title,
    lat,
    long,
    status,
    UdapName,
    Name,
    OvPrio,
    VrachtPrio,
    Profile
  ) => {
    const key = Name + "-" + UdapName;
    let bgColor = `transparent`;
    if (status === "ok") bgColor = "#15d18f";
    if (status === "warning") bgColor = "#f7d033";
    if (status === "info") bgColor = "#85b6ff";
    if (status === "error") bgColor = "#e35f00";
    if (status === "offline") bgColor = "#d0dbe6";
    if (status === "current") bgColor = "#9c40df";
    if (
      (status === "ok" && showOk === true) ||
      (status === "error" && showError === true) ||
      (status === "offline" && showOffline === true) ||
      (OvPrio === "true" && showOv === true) ||
      (VrachtPrio === "true" && showVracht === true)
    )
      return (
        <Marker
          position={[lat, long]}
          title={title}
          icon={L.divIcon({
            html: ReactDOMServer.renderToString(<MapMarker status={status} />),
            className: "dummy",
          })}
          eventHandlers={{
            click: (e) => {
              handleOnClick(key);
            },
          }}
        />
      );
  };

  const markerGroup = (type) =>
    geoJSON.map((i) => {
      return (
        i.properties.Status === type && (
          <div key={i.properties.UdapName}>
            {geoMarker(
              i.properties.UdapName + " / " + i.properties.Name,
              i.properties.Latitude,
              i.properties.Longitude,
              i.properties.Status,
              i.properties.UdapName,
              i.properties.Name,
              i.properties.OvPrio,
              i.properties.VrachtPrio,
              i.properties.Profile
            )}
          </div>
        )
      );
    });

  return (
    <div className="pageCutter">
      <div className="mapcontainer">
        <MapContainer
          center={center}
          ref={mapRef}
          zoom={zoom}
          scrollWheelZoom={true}
          style={{ width: `100%`, height: `100%` }}
          maxZoom={18}
          zoomControl={false}
        >
          <TileLayers center={center} zoom={zoom} />
          {statusOptions.map((status) => (
            <div key={uuid()}>
              <MarkerClusterGroup
                zoomToBoundsOnClick={true}
                iconCreateFunction={createClusterCustomIcon(status)}
                showCoverageOnHover={false}
                //disableClusteringAtZoom={17}
              >
                {markerGroup(status)}
              </MarkerClusterGroup>
            </div>
          ))}
          {context.pins.value.map((pin) => (
            <Marker key={pin.id} position={[pin.lat, pin.lon]}>
              <Popup className={styles.popup}>
                <Button
                  onClick={() =>
                    window.open(
                      `http://maps.google.com/maps?q=&layer=c&cbll=${pin.lat},${pin.lon}&cbp=`,
                      "_blank"
                    )
                  }
                >
                  StreetView
                </Button>
                <Button
                  color="primary"
                  danger
                  icon
                  onClick={() => context.pins.delete(pin.id)}
                >
                  <Trash16 />
                </Button>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
}
