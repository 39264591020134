import styles from "./filterHeader.module.scss";
import GeoDataContext from "../../../../../../store/geo-data-context";
import { useTheme } from "@yunex/yds-react";
import {
  StatusError16,
  StatusSuccess16,
  StatusOffline16,
  Publictransport16,
  Truck16,
} from "@yunex/yds-icons";
import { useState, useContext } from "react";

export default function FilterHeader({
  activeFilter,
  setActiveFilter,
  setFilterValue,
  setItemCount,
}) {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const { palette } = useTheme();
  const context = useContext(GeoDataContext);

  let okCount = 0;
  let warningCount = 0;
  let errorCount = 0;
  let offlineCount = 0;
  let ovCount = 0;
  let vrachtCount = 0;

  context.geoData.map((i) => {
    if (i.properties.Status === "ok") {
      okCount = okCount + 1;
    }
    if (i.properties.Status === "warning") {
      warningCount = warningCount + 1;
    }
    if (i.properties.Status === "error") {
      errorCount = errorCount + 1;
    }
    if (i.properties.Status === "offline") {
      offlineCount = offlineCount + 1;
    }
    if (i.properties.OvPrio === "true") {
      ovCount = ovCount + 1;
    }
    if (i.properties.VrachtPrio === "true") {
      vrachtCount = vrachtCount + 1;
    }
  });

  const changeShownMarkers = (value) => {
    if (value == "ok") {
      context.showError.set(false);
      context.showOffline.set(false);
      context.showOk.set(true);
      context.showVracht.set(false);
      context.showOv.set(false);
    }

    if (value == "ov") {
      context.showError.set(false);
      context.showOffline.set(false);
      context.showOk.set(false);
      context.showVracht.set(false);
      context.showOv.set(true);
    }

    if (value == "vracht") {
      context.showError.set(false);
      context.showOffline.set(false);
      context.showOk.set(false);
      context.showVracht.set(true);
      context.showOv.set(false);
    }

    if (value == "error") {
      context.showError.set(true);
      context.showOffline.set(false);
      context.showOk.set(false);
      context.showVracht.set(false);
      context.showOv.set(false);
    }

    if (value == "offline") {
      context.showError.set(false);
      context.showOffline.set(true);
      context.showOk.set(false);
      context.showVracht.set(false);
      context.showOv.set(false);
    }

    if (value == null) {
      context.showError.set(true);
      context.showOffline.set(true);
      context.showOk.set(true);
      context.showVracht.set(true);
      context.showOv.set(true);
    }
  };

  const handleFilter = (value, count) => {
    setFilterValue(value);
    changeShownMarkers(value);
    setActiveFilter(value);
    setItemCount(count);
  };

  const toggleSubMenu = () => {
    setShowSubMenu((prev) => !prev);
  };

  const filterButtons = [
    { label: "All", value: null, count: context.geoData.length, icon: null },
    // {
    //   label: "Ok",
    //   value: "ok",
    //   count: okCount,
    //   icon: <StatusSuccess16 color={palette.status.ok} />,
    // },
    // {
    //   label: "Error",
    //   value: "error",
    //   count: errorCount,
    //   icon: <StatusError16 color={palette.status.error} />,
    // },
    // {
    //   label: "Offline",
    //   value: "offline",
    //   count: offlineCount,
    //   icon: <StatusOffline16 color={palette.status.offline} />,
    // },
    {
      label: "VrachtPrio",
      value: "vracht",
      count: vrachtCount,
      icon: <Truck16 color={palette.status.ok} />,
    },
    {
      label: "OVPrio",
      value: "ov",
      count: ovCount,
      icon: <Publictransport16 color={palette.status.error} />,
    },
  ];

  const visibleButtons = filterButtons.slice(0, 4);
  const hiddenButtons = filterButtons.slice(2);

  return (
    <div className={`${styles.filter_buttons_container} MuiBox-root`}>
      {visibleButtons.map((button) => (
        <button
          key={button.label}
          onClick={() => handleFilter(button.value, button.count)}
          className={`${activeFilter === button.value ? styles.active : ""} ${
            styles.filter_button
          }`}
        >
          <div className={styles.inner_button_wrapper}>
            {button.icon && (
              <div className={styles.button_icon}>
                {button.icon}
                {button.count}
              </div>
            )}
            <span
              className={
                button.icon ? styles.button_text : styles.default_button_text
              }
            >
              {button.label}
            </span>
          </div>
        </button>
      ))}
      {/* <div className={styles.more_icon} onClick={toggleSubMenu}>
        
      </div> */}
      {/* {showSubMenu && (
        <div className={styles.submenu}>
          {hiddenButtons.map((button) => (
            <button
              key={button.label}
              onClick={() => handleFilter(button.value)}
              className={`${activeFilter === button.value ? styles.active : ''} ${styles.filter_button}`}
            >
              {button.label}
            </button>
          ))}
        </div>
      )} */}
    </div>
  );
}
