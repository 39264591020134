import styles from "./locationPinMenu.module.scss";
import { useState, useContext } from "react";
import { Card, CardContent, Button, TextFormInput, FormControlLabel, Switch } from "@yunex/yds-react";
import { v4 as uuid } from "uuid";
import GeoDataContext from "../../../store/geo-data-context";

export default function LocationPinMenu() {
  const context = useContext(GeoDataContext);
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [latLon, setLatLon] = useState("");
  const [isError, setIsError] = useState(false);
  const [isAltInput, setIsAltInput] = useState(false);

  console.log(isAltInput);

  const isValidCoordinates = (lat, lon) => {
    const latPattern = /^-?([1-8]?[0-9]\.{1}\d+|90\.{1}0+)$/;
    const lonPattern = /^-?((1[0-7][0-9]|0?[0-9]?[0-9])\.{1}\d+|180\.{1}0+)$/;
    return latPattern.test(lat) && lonPattern.test(lon);
  };

  function isValidLatLon(coordinateString) {
    const regex = /^\s*-?\d+(\.\d+)?\s*[, ]\s*-?\d+(\.\d+)?\s*$/;

    if (!regex.test(coordinateString)) {
      return false;
    }

    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isAltInput) {
      const latLon = e.target.latlon.value;
      const isValid = isValidLatLon(latLon);
      if (!isValid) {
        setIsError(true);
        return;
      }
      setIsError(false);
      const comma = latLon.includes(",");
      const [ latitude, longitude ] = comma ? latLon.split(",") : latLon.split(" "); 
      const id = uuid();
      const pinObject = {
        id: id,
        lat: latitude.trim(),
        lon: longitude.trim(),
      };
      context.pins.add(pinObject);
      context.map.center.set([latitude.trim(), longitude.trim()]);
      context.map.zoom.set(17);
      e.target.latlon.value = "";
      setLatLon("");
    } else {
      const latitude = e.target.lat.value;
      const longitude = e.target.lon.value;
      
    
      if (!isValidCoordinates(latitude, longitude)) {
        // Handle invalid coordinates here
        setIsError(true);
        return;
      }
      setIsError(false);
  
      const id = uuid();
      const pinObject = {
        id: id,
        lat: latitude,
        lon: longitude,
      };
      context.pins.add(pinObject);
      context.map.center.set([latitude, longitude]);
      context.map.zoom.set(17);
      e.target.lat.value = "";
      e.target.lon.value = "";
      setLat("");
      setLon("");
    }
   
  };


  const handleClearPins = (e) => {
    e.preventDefault();
    context.pins.clear();
  };

  const handleLatChange = (e) => {
    e.preventDefault();
    setLat(e.target.value);
  };

  const handleLonChange = (e) => {
    e.preventDefault();
    setLon(e.target.value);
  };

  const handleLatLonChange = (e) => {
    e.preventDefault();
    setLatLon(e.target.value);
  }

  const handleSwitch = (e) => {
    setIsAltInput(!isAltInput);
  }

  return (
    <div>
      <Card style={{ height: "fit-content" }}>
        <CardContent>
        <FormControlLabel control={<Switch checked={isAltInput} onChange={handleSwitch}/>} label="alternative input"/>
          <form onSubmit={handleSubmit}>

            {isAltInput ? (
              <TextFormInput
              label="Lat, lon"
              value={latLon}
              name="latlon"
              onChange={handleLatLonChange}
              error={isError}
              errorMessage={
                isError
                  ? "Coordinate is not valid"
                  : ""
              }
            />
            ):(
              <>
            <TextFormInput
              label="Latitude"
              value={lat}
              name="lat"
              onChange={handleLatChange}
              error={isError}
              errorMessage={
                isError
                  ? "Either latitude or longitude isn't valid coordinate"
                  : ""
              }
            />

            <TextFormInput
              label="Longitude"
              value={lon}
              name="lon"
              onChange={handleLonChange}
              error={isError}
              errorMessage={
                isError
                  ? "Either latitude or longitude isn't valid coordinate"
                  : ""
              }
            />
            
            </>
            )}
            <Button
              style={{
                marginTop: "1rem",
                marginRight: "1rem",
              }}
              color="primary"
              type="submit"
              disabled={!isAltInput && (!lat || !lon) || isAltInput && !latLon}
            >
              Add Pin
            </Button>
            <Button
              style={{
                marginTop: "1rem",
              }}
              color="secondary"
              onClick={handleClearPins}
            >
              Clear pins
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
