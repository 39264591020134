import React from 'react'
import PropTypes from 'prop-types'

export function Sun24({ theme }) {
    Sun24.propTypes = {
        theme: PropTypes.node.isRequired,
    }

    let fillcolor
    if (theme === 'light') {
        fillcolor = 'black'
    } else {
        fillcolor = 'transparent'
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
                d="M12 6.402A5.6 5.6 0 006.398 12 5.6 5.6 0 0012 17.598 5.6 5.6 0 0017.602 12 5.6 5.6 0 0012 6.402m0 2A3.604 3.604 0 0115.602 12 3.604 3.604 0 0112 15.598 3.604 3.604 0 018.398 12 3.604 3.604 0 0112 8.402M13 5h-2V0h2zm0 19h-2v-5h2zm11-11h-5v-2h5zM5 13H0v-2h5zm2.063-7.07L5.93 7.063 2.54 3.667l1.128-1.133zm14.402 14.402l-1.133 1.133-3.395-3.399 1.13-1.128zM20.332 2.535l1.129 1.133-3.395 3.394-1.128-1.128zM5.93 16.938l1.133 1.132-3.395 3.39-1.133-1.128zm0 0"
                fill={fillcolor}
            />
        </svg>
    )
}
