import { useState, useMemo, useContext } from "react";
import "./trafficController.scss";
import GeoDataContext from "../../../../../store/geo-data-context";
import { AgGridReact } from "ag-grid-react";
import { IconRenderer, ListHeader } from "@yunex/yds-react-composites";
import {
  TextField,
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  GRID_ROW_HEIGHT_LARGE,
  Stack,
  TextStatusIconCellRenderer,
} from "@yunex/yds-react";
import { useEffect } from "react";
import { useRef } from "react";
import styles from "./trafficController.module.scss";
import FilterHeader from "./filterHeader/filterHeader";

export default function trafficController() {
  const [rowData, setRowData] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [isCompactSize, setIsCompactSize] = useState(true);
  const [filterValue, setFilterValue] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const gridApiRef = useRef(null);
  const context = useContext(GeoDataContext);
  const geoJSON = context.geoData;
  const [itemCount, setItemCount] = useState(geoJSON.length);

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.deselectAll();
      gridApiRef.current.clearFocusedCell();
    }
  }, [context.deselect.value]);

  useEffect(() => {
    if (context.focusedItem.value != null) {
      const selectedRow = gridApi.getSelectedRows();
      if (selectedRow.length > 0) {
        const selectedRowKey =
          selectedRow[0].properties.Name +
          "-" +
          selectedRow[0].properties.UpdapName;
        if (selectedRowKey !== context.focusedItem.value) {
          setTimeout(() => {
            const rowNode = gridApiRef.current.getRowNode(
              context.focusedItem.value
            );
            const rowIndex = rowNode.rowIndex;
            if (!rowNode.selected) {
              rowNode.setSelected(true);
              gridApi.ensureIndexVisible(rowIndex);
            }
          }, 0);
        }
      } else {
        setTimeout(() => {
          const rowNode = gridApiRef.current.getRowNode(
            context.focusedItem.value
          );
          const rowIndex = rowNode.rowIndex;
          if (!rowNode.selected) {
            rowNode.setSelected(true);
            gridApi.ensureIndexVisible(rowIndex);
          }
        }, 0);
      }
    }
  }, [context.focusedItem.value]);

  const filteredRowData = () => {
    if (filterValue) {
      if (filterValue == "ov") {
        return rowData.filter((item) => item.properties.OvPrio == "true");
      } else if (filterValue == "vracht") {
        return rowData.filter((item) => item.properties.VrachtPrio == "true");
      } else {
        return filterValue
          ? rowData.filter((item) => item.properties.Status === filterValue)
          : rowData;
      }
    } else {
      return rowData;
    }
  };

  // const filteredRowData = filterValue
  //   ? rowData.filter((item) => item.properties.Status === filterValue)
  //   : rowData;

  const headerProps = {
    subtitle: `${itemCount} items getoond`,
    title: `Devices`,
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    gridApiRef.current = params.api;
    setRowData(geoJSON);
  };

  const handleChange = ({ target }) => {
    gridApi?.setQuickFilter(target.value);
  };

  const onFirstDataRendered = (params) => {
    params.api?.sizeColumnsToFit();
  };

  const handleOnClick = (key) => {
    console.log(key);
    context.popup.dialogOpen.set(!context.popup.dialogOpen.value);
    context.focusedItem.set(key);
    const selectedItem = geoJSON.filter((i) => {
      const itemKey = i.properties.Name + "-" + i.properties.UdapName;
      return itemKey === key;
    })[0];
    context.map.center.set([
      selectedItem.properties.Latitude,
      selectedItem.properties.Longitude,
    ]);
    context.map.zoom.set(17);
    context.selectedVri.set(selectedItem);
  };

  const handleItemFocus = (key) => {
    context.focusedItem.set(key);
  };

  const handleItemBlur = () => {
    context.focusedItem.set(null);
  };

  const ListRenderer = ({ data }) => {
    const secondaryRowText = data.properties.DescriptiveName;
    const key = data.properties.Name + "-" + data.properties.UdapName;

    return (
      <ListItem
        sx={{
          flex: 1,
          width: "100%",
          height: "100%",
          paddingLeft: 0,
          paddingRight: 0,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "& .MuiTypography-root": {
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
        }}
        onFocus={() => handleItemFocus(key)}
        onClick={() => handleOnClick(key)}
        onBlur={handleItemBlur}
        key={key}
      >
        <ListItemIcon
          sx={{
            ".css-j2pkwr-MuiSvgIcon-root": {
              width: 20,
            },
          }}
        >
          <IconRenderer value={data.properties.Status} />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <b>{data.properties.Name}</b> - {data.properties.UdapName}
            </>
          }
          secondary={secondaryRowText}
        />
      </ListItem>
    );
  };

  const columnDefs = useMemo(
    () => [
      {
        field: "properties.Name",
        hide: true,
      },
      {
        field: "properties.UdapName",
        hide: true,
      },
      {
        field: "properties.DescriptiveName",
        hide: true,
      },
      {
        field: "properties.Status",
        cellRenderer: TextStatusIconCellRenderer,
        hide: true,
      },
      {
        field: "properties.Latitude",
        hide: true,
      },
      {
        field: "properties.Longitude",
        hide: true,
      },
      {
        field: "decUdapName",
        hide: true,
        valueGetter: (params) => {
          const udapName = params.data.properties.UdapName;
          const hexRegionCode = udapName.slice(0, 4);
          const hexVriCode = udapName.slice(4, 8);
          let decRegionCode = parseInt(hexRegionCode, 16);
          let decVriCode = parseInt(hexVriCode, 16);
          return decRegionCode + "." + decVriCode;
        },
      },
      {
        headerName: "Device", // Add 'devices' as the header name
        field: "dummy", // Add a dummy field name (it doesn't exist in your data)
        hide: false, // Make sure it's not hidden
        resizable: false, // Make it not resizable
        minWidth: 250, // Set a minimum width
        maxWidth: 290, // Set a maximum width (optional)
        filter: false,
        suppressMovable: true,
        cellRenderer: ListRenderer,
      },
    ],
    [isCompactSize]
  );

  return (
    <Stack flex={1} height={"Calc(100vh - 135px)"} width={"100%"}>
      <Box flex={1} className={styles.first_block}>
        <ListHeader {...headerProps} />
      </Box>
      <Box flex={1} className={styles.block}>
        <TextField
          style={{ width: "100%" }}
          type="search"
          placeholder="Zoeken"
          onChange={handleChange}
        />
      </Box>
      <Box
        flex={1}
        className="block"
        sx={{
          ".MuiBox-root .css-1p7eyd0": {
            display: "none",
          },
          ".css-1k5q1rt-MuiGrid-root": {
            paddingTop: 0,
          },
        }}
      >
        <FilterHeader
          setFilterValue={setFilterValue}
          setActiveFilter={setActiveFilter}
          activeFilter={activeFilter}
          setItemCount={setItemCount}
        />
      </Box>
      <Box
        className="ag-theme-alpine yds-grid-cell-selection ag-compact-size"
        style={{
          height: "100%",
          overflowy: "none",
        }}
        sx={{
          ".ag-body-viewport.ag-layout-normal": {
            overflowY: `none`,
          },
        }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          // ref={gridApiRef}
          getRowHeight={GRID_ROW_HEIGHT_LARGE}
          rowHeight={GRID_ROW_HEIGHT_LARGE}
          headerHeight={46}
          onFirstDataRendered={onFirstDataRendered}
          onGridReady={onGridReady}
          rowSelection="single"
          alwaysShowVerticalScroll={false}
          scrollbarWidth={12}
          onBodyScroll={true}
          rowBuffer={40}
          rowData={filteredRowData()}
          //rowData={filteredRowData}
          getRowId={(data) => {
            return (
              data.data.properties.Name + "-" + data.data.properties.UdapName
            );
          }}
          suppressRowDeselection={false}
          includeHiddenColumnsInQuickFilter={true}
          includeHiddenColumnsInAdvancedFilter={true}
        />
      </Box>
    </Stack>
  );
}
