import React from "react";
import PropTypes from "prop-types";
import { AppHeader, TopNavItem } from "@yunex/yds-react";
import { NavLink } from "react-router-dom";
import { LightSwitch } from "./Lightswitch/lightswitch";
import { HamburgerMenu } from "../hamburgerMenu";
import useWindowSize from "../../customHooks/windowSize";

export function Header({
  menuOpen,
  setMenuOpen,
  selectedIndex,
  setSelectedIndex,
  onClick,
}) {
  Header.propTypes = {
    menuOpen: PropTypes.bool,
    setMenuOpen: PropTypes.func,
    selectedIndex: PropTypes.number,
    setSelectedIndex: PropTypes.func,
  };
  const size = useWindowSize();
  const headerButton = {
    textAlign: "center",
    maxWidth: "150px",
  };

  const handleItemClick = (index) => {
    setSelectedIndex(index);
  };

  const handleClick = (e) => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="header" onClick={handleClick}>
      <AppHeader
        appName={
          <div
            style={{
              position: "relative",
            }}
          >
            <h3>iVRI Portaal</h3>
          </div>
        }
        sx={{
          position: "fixed",
          height: "50px",
          pl: "1rem",
        }}
      >
        {size.width > 720 && (
          <>
            <TopNavItem
              sx={{
                ...headerButton,
                color: selectedIndex === 1 && "#106ecc",
              }}
              onClick={() => handleItemClick(1)}
              component={NavLink}
              to="/map"
            >
              <h4>iVRI Kaart</h4>
            </TopNavItem>
            <TopNavItem
              sx={{ ...headerButton, color: selectedIndex === 3 && "#106ecc" }}
              onClick={() => handleItemClick(3)}
              component={NavLink}
              to="/priotalker"
            >
              <h4>Priotalker</h4>
            </TopNavItem>
            <TopNavItem
              sx={{ ...headerButton, color: selectedIndex === 4 && "#106ecc" }}
              onClick={() => handleItemClick(4)}
              component={NavLink}
              to="/storingen"
            >
              <h4>v2x storingen</h4>
            </TopNavItem>
          </>
        )}

        {size.width <= 720 && (
          <div
            style={{
              position: "absolute",
              right: "1.6rem",
              width: "20px",
              height: "21px",
            }}
          >
            <HamburgerMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          </div>
        )}

        <div
          style={{
            position: "absolute",
            right: size.width > 850 ? "1.3rem" : "Calc(2rem + 35px)",
          }}
        >
          <LightSwitch />
        </div>
      </AppHeader>
    </div>
  );
}
